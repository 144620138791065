//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {bgimgUrl2} from '/src/utils/config.js'
import {
	areaList
} from '@vant/area-data'
import {
	getLogin,
	getSchoolInfo,
} from '../../api'
export default {
	name: "search",
	data() {
		return {
			bgimgUrl2:'',
			areaList,
			value: '',
			status: false,
			show: false,
			columns: [],
			schoolValue: '',
			levelValue: '',
			classValue: '',
			schoolShow: false,
			formData: {
				grade_id: '',
				username: ''
			}
		}
	},
	created() {
		this.bgimgUrl2 = bgimgUrl2;
		this.get_SchoolInfo()
	},
	methods: {
		get_Login() {
			getLogin(this.formData).then(res => {
				console.log(res)
				if (res.data.code == 0) {
					this.$toast.fail(res.data.msg)
				} else {
					localStorage.setItem('token', res.data.data.userinfo.token)
					this.$toast.success(res.data.msg)
					setTimeout(() => {
						this.$router.push({
							name: 'paiHang',
							query: {
								id: res.data.data.userinfo.grade.type
							}
						})
					}, 500)
				}
			})
		},
		get_SchoolInfo() {
			getSchoolInfo().then(res => {
				console.log(res)
				this.columns = res.data.data
			})
		},
		onOpen(type) {
			if (type === 'diqu') {
				this.show = true
			} else if (type === 'school') {
				this.schoolShow = true
			}
		},
		onCancel() {
			this.schoolShow = false
		},
		onConfirm(e, value) {
			this.schoolValue = e[1]
			this.levelValue = e[2]
			//this.classValue = e[2]
			this.formData.grade_id = this.columns[value[0]].children[value[1]].children[value[2]].id
			this.schoolShow = false
		}
	}
}
